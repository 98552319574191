<div [hidden]="preferences.dataModel && (preferences.dataModel.password_change_needed || preferences.dataModel.require_two_factor)" class="d-flex justify-content-end">
  @if (appDataService.public.node.languages_enabled.length > 1) {
    <div id="LanguagePickerBox" class="order-2" [ngClass]="appDataService.public.node.wizard_done ? 'language-box' : 'language-box-extended'">
      <label for="language-picker-select" class="visually-hidden">{{'Select your language' | translate}}</label>
      <ng-select labelForId="language-picker-select" [clearable]="false" (change)="onChangeLanguage();" [(ngModel)]="translationService.language">
        @for (lang of appDataService.languages_enabled_selector | orderBy: 'native'; track lang) {
          <ng-option [value]="lang.code">
            <span [attr.data-cy]="lang.code">{{lang.native}}</span>
          </ng-option>
        }
      </ng-select>
    </div>
  }
  @if (utils.showUserStatusBox(authentication, appDataService)) {
    <div id="LoginStatusBox">
      <span id="HomeLinkBox">
        @if (authentication.session.role !== 'whistleblower') {
          <span>
            <a id="HomepageLink" [href]="'#'+authentication.session.homepage" ngbTooltip="{{'Home' | translate }}">
              <i class="fa-solid fa-fw fa-home"></i>
            </a>
            <span class="text-separator">|</span>
          </span>
        }
      </span>
      @if (authentication.session.role !== 'whistleblower' && !authentication.session.properties.management_session) {
        <span id="PreferencesLinkBox">
          <a id="PreferencesLink" [href]="'#'+authentication.session.preferencespage" ngbTooltip="{{'Preferences' | translate}}">
            <i class="fa-solid fa-fw fa-user"></i>
          </a>
          <span class="text-separator">|</span>
        </span>
      }
      @if (authentication.session && authentication.session.role !== 'whistleblower') {
        <span id="SupportLinkBox">
          <a id="SupportLink" (click)="utils.openSupportModal(appDataService)" ngbTooltip="{{'Request support' | translate}}">
            <i class="fa-solid fa-fw fa-life-ring"></i>
          </a>
          <span class="text-separator">|</span>
        </span>
      }
      <span id="LogoutLinkBox">
        <i class="glyphicon glyphicon-user"></i>
      </span>
      <span id="LogoutLinkBox">
        <a href="#" id="LogoutLink" (click)="onLogout($event)" ngbTooltip="{{'Logout' | translate}}">
          <i class="pointer fa-solid fa-fw fa-sign-out-alt"></i>
          @if (authentication.session.role === 'whistleblower') {
            <span>
              <span></span>
              <span>{{'Logout'|translate}}</span>
            </span>
          }
        </a>
      </span>
    </div>
  }
</div>
@if (utils.showWBLoginBox()) {
  <div id="WhistleblowerLoginBox" class="ms-1 mb-1">
    <src-receipt ></src-receipt>
  </div>
}

